import {I18n} from 'common/translator/i18n';
import Bugsnag from 'common/bugsnag';

import {MonorailTracker} from '../../common/analytics';
import {
  constructLink,
  getVariantsFromAttributeString,
} from '../payButton/utils';
import {Cart, PaymentOption} from '../payButton/types';
import {
  AvailableLoanType,
  InstallmentPlan,
  ModalType,
  ModalUserAction,
  VariantModalDetails,
  SamplePlan,
  InstallmentsBannerType,
} from '../../types';

import {
  getFormattedSamplePlans,
  getDynamicPDPTemplateContents,
  getNavigationButtons,
  getDynamicPDPText,
  InstallmentsModal,
} from './utils';

export class ShopifyInstallmentsSamplePlansModal
  extends HTMLElement
  implements InstallmentsModal
{
  private _i18n: I18n | null = null;
  private _closeButtons?: NodeListOf<Element>;
  private _samplePlans: SamplePlan[] = [];
  private _modalToken: string;
  private _continueToCheckoutButton?: Element;
  private _permalink?: string;
  private _monorailTracker: MonorailTracker;
  private _trackContinueToCheckout = true;
  private _installmentPlans: InstallmentPlan[];
  private _priceWithoutInterest: string;
  private _variantInfo?: VariantModalDetails;
  private _cart?: Cart;
  private _initialized = false;
  private _metaType: InstallmentsBannerType;

  constructor(
    modalToken: string,
    monorailTracker: MonorailTracker,
    installmentPlans: InstallmentPlan[],
    priceWithoutInterest: string,
    metaType: InstallmentsBannerType,
    variantInfo?: VariantModalDetails,
    cart?: Cart,
  ) {
    super();
    this._modalToken = modalToken;
    this._monorailTracker = monorailTracker;
    this._trackContinueToCheckout = variantInfo?.available ?? true;
    this._installmentPlans = installmentPlans;
    this._priceWithoutInterest = priceWithoutInterest;
    this._variantInfo = variantInfo;
    this._cart = cart;
    this._metaType = metaType;
  }

  get focusLockTarget() {
    return this.querySelector('#shopify-payment-terms-modal')! as HTMLElement;
  }

  get rootContainer() {
    return this;
  }

  handleClose = () => {
    this._monorailTracker.trackModalAction(
      this._modalToken,
      ModalUserAction.Close,
      this._permalink,
    );
    const event = new Event('shopify_modal_close');
    this.dispatchEvent(event);
  };

  handleEscKey = (evt: KeyboardEvent) => {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      this.handleClose();
    }
  };

  handleContinueToCheckout = () => {
    if (!this._trackContinueToCheckout) return;

    this._monorailTracker.trackModalAction(
      this._modalToken,
      ModalUserAction.ContinueToCheckout,
      this._permalink,
    );
  };

  async connectedCallback() {
    if (this._initialized) return;
    await this._initTranslations();
    await this._initContent();

    this._closeButtons = this.querySelectorAll('.btn__close');
    if (!this._closeButtons || this._closeButtons.length === 0) return;

    this._closeButtons.forEach((btn) =>
      btn.addEventListener('click', this.handleClose),
    );
    // Autofocus on the first element inside modal
    (this._closeButtons[0] as HTMLButtonElement).focus();

    if (this._continueToCheckoutButton) {
      this._continueToCheckoutButton.addEventListener(
        'click',
        this.handleContinueToCheckout,
      );
    }
    window.addEventListener('keydown', this.handleEscKey);
    this._initialized = true;
  }

  disconnectedCallback() {
    if (!this._closeButtons) return;

    this._closeButtons.forEach((btn) =>
      btn.removeEventListener('click', this.handleClose),
    );

    if (this._continueToCheckoutButton) {
      this._continueToCheckoutButton.removeEventListener(
        'click',
        this.handleContinueToCheckout,
      );
    }

    window.removeEventListener('keydown', this.handleEscKey);
  }

  getModalSamplePlans(): SamplePlan[] {
    return this._samplePlans;
  }

  getPermalink(): string | undefined {
    return this._permalink;
  }

  getModalToken(): string {
    return this._modalToken;
  }

  getModalType() {
    const hasZeroInterestLoan = this._samplePlans?.some(
      (plan) => plan.apr === 0 && plan.loanType === AvailableLoanType.Interest,
    );

    if (hasZeroInterestLoan) {
      const onlyZeroInterestLoans = this._samplePlans?.every(
        (plan) => plan.apr === 0,
      );
      return onlyZeroInterestLoans
        ? ModalType.ZeroInterestOnly
        : ModalType.ZeroInterest;
    }

    // At this point, we know that no zero interest loans are present
    const isAdaptiveRange =
      this._samplePlans?.some(
        (plan) => plan.loanType === AvailableLoanType.SplitPay,
      ) &&
      this._samplePlans?.some(
        (plan) => plan.loanType === AvailableLoanType.Interest,
      );

    return isAdaptiveRange ? ModalType.Adaptive : ModalType.InterestOnly;
  }

  /**
   * @returns {string} Text for the button
   */
  getButtonText() {
    if (!this._i18n) return '';
    return this._variantInfo?.available === false
      ? this._i18n.translate('modal.sample_plan_contents.unavailable')
      : this._i18n.translate('modal.sample_plan_contents.continue_to_checkout');
  }

  private async _initTranslations(): Promise<void> {
    if (this._i18n) return;
    try {
      const locale = I18n.getDefaultLanguage();
      const dictionary = await import(`./translations/${locale}.json`);
      this._i18n = new I18n({[locale]: dictionary});
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  }

  private async _initContent(): Promise<void> {
    if (!this._i18n) return;

    const template = document.createElement('template');

    this._samplePlans = getFormattedSamplePlans(
      this._installmentPlans,
      this._priceWithoutInterest,
    );

    const {subTitle, legalCopy} = getDynamicPDPText(
      this._i18n,
      this._samplePlans.length,
      this._priceWithoutInterest,
    );

    template.innerHTML = getDynamicPDPTemplateContents(
      this._i18n,
      subTitle,
      legalCopy,
      this._samplePlans,
    );
    this.appendChild(template.content.cloneNode(true));

    const navigationButtons =
      this.getElementsByClassName('navigation-buttons')[0];

    // No Navigation buttons nor eligibility disclaimer for checkout
    if (this._metaType === InstallmentsBannerType.Checkout) {
      const eligibility = this.getElementsByClassName('check_eligibility')[0];
      navigationButtons.classList.add('hidden-navigation-buttons');
      eligibility.classList.add('hidden-navigation-buttons');
      return;
    }

    const buttonText = this.getButtonText();

    navigationButtons.innerHTML = getNavigationButtons(
      window.location.origin,
      this._modalToken,
      buttonText,
      this._variantInfo,
      this._cart,
    );
    this._continueToCheckoutButton =
      navigationButtons.querySelector('shop-pay-button')!;

    // If no cart token is defined, it means that the modal was built for PDP
    this._permalink = this._cart
      ? this._cart.token
      : constructLink({
          storeUrl: window.location.origin,
          variants: this._variantInfo
            ? getVariantsFromAttributeString(
                this._variantInfo.idQuantityMapping,
              )
            : [],
          paymentOption: PaymentOption.ShopPayInstallments,
          source: 'installments_modal',
          sourceToken: this._modalToken,
        });
  }
}
