import {v4 as uuidv4} from 'uuid';
import {I18n} from 'common/translator/i18n';

import {defineCustomElement} from '../../../common/init';
import Bugsnag from '../../../common/bugsnag';
import {ShopPayLogo} from '../../../common/shop-pay-logo';
import {
  PAYMENT_TERMS_TEMPLATE,
  FAILED_TO_LOAD_HTML_ERROR_MESSAGE,
  SHOP_PAY_LOGO,
} from '../constants';
import FocusLock from '../FocusLock';
import {ShopifyInstallmentsModal} from '../modal-contents';
import {
  AvailableLoanType,
  BannerTemplateCodeSignature,
  Term,
  MinIneligibleMessageType,
  InstallmentsBannerType,
  InstallmentsBannerContent,
  LegacyCartShopifyMetadata,
  LegacyProductShopifyMetadata,
  LegacyVariant,
} from '../../../types';
import {
  formatPrice,
  inferBackgroundColor,
  cartSubtotalSelectorsForTheme,
  convertPriceToNumber,
  confirmCartSubtotalAttribute,
  getNewProductVariantId,
} from '../utils';
import {
  closePaymentTermsModal,
  openPaymentTermsModal,
} from '../utils/termsModal';
import {isLegacyShopifyMetadata} from '../utils/metadataValidation';

import {MonorailTrackerPaymentTerms} from './MonorailTrackerPaymentTerms';

interface ComponentMetadata {
  name: string;
  shopifyMeta: any;
  variantId: string | null;
}

export class ShopPayBanner extends HTMLElement {
  static get observedAttributes() {
    return ['variant-id', 'shopify-meta'];
  }

  private _variants?: LegacyVariant[];
  private _currentVariantId?: number;
  private _pricePerTerm?: string;
  private _minPrice = '$50';
  private _minPriceNumber?: number;
  private _maxPrice = '$3000';
  private _maxPriceNumber?: number;
  private _open = false;
  private _eligible = false;
  private _numberOfPaymentTerms = 4;
  private _hasChangeEventListener = false;
  private _loanTypes: AvailableLoanType[] = [];
  private _monorailTracker: MonorailTrackerPaymentTerms;
  private _modalMonorailTracker: MonorailTrackerPaymentTerms;
  private _lastLearnMoreEventListener?: () => void;
  private _metaType: InstallmentsBannerType = InstallmentsBannerType.Product;
  private _backgroundColor = '';
  private _didMount = false;
  private _i18n: I18n | null = null;

  constructor() {
    super();

    this._monorailTracker = new MonorailTrackerPaymentTerms({
      elementName: 'shop-pay-banner',
    });
    this._modalMonorailTracker = new MonorailTrackerPaymentTerms({
      elementName: 'shopify-installments-modal',
    });

    if (!customElements.get('shopify-installments-modal')) {
      customElements.define(
        'shopify-installments-modal',
        ShopifyInstallmentsModal,
      );
    }

    if (!customElements.get('shop-pay-logo')) {
      customElements.define('shop-pay-logo', ShopPayLogo);
    }

    this.attachShadow({mode: 'open'}).innerHTML = PAYMENT_TERMS_TEMPLATE;
  }

  async initTranslations() {
    if (this._i18n) return;
    try {
      const locale = I18n.getDefaultLanguage();
      const dictionary = await import(`../translations/${locale}.json`);
      this._i18n = new I18n({[locale]: dictionary});
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  }

  attributeChangedCallback() {
    if (this._didMount) {
      this.updateBanner();
    }
  }

  async connectedCallback() {
    await this.initTranslations();
    this.updateBanner();
    this._didMount = true;
  }

  updateBanner() {
    try {
      const shopifyMeta = this.getAttribute('shopify-meta');
      if (shopifyMeta) {
        const parsedShopifyMeta = JSON.parse(shopifyMeta);
        this._backgroundColor = inferBackgroundColor(this.shadowRoot);

        if (
          isLegacyShopifyMetadata(
            parsedShopifyMeta,
            this._monorailTracker.trackInvalidInstallmentBannerMetadata.bind(
              this._monorailTracker,
            ),
          )
        ) {
          if (parsedShopifyMeta.type === InstallmentsBannerType.Cart) {
            this._monorailTracker.trackElementImpression(
              InstallmentsBannerType.Cart,
            );
            this._metaType = InstallmentsBannerType.Cart;
            this.handleCartMeta(parsedShopifyMeta);
          } else {
            // Some shops are breaking if we require the product type.
            // If no type is provided, assume it's legacy => product
            this._monorailTracker.trackElementImpression(
              InstallmentsBannerType.Product,
            );
            this._metaType = InstallmentsBannerType.Product;
            this.handleProductMeta(parsedShopifyMeta);
          }
        }
        this.updateLearnMoreButtonAndModal();
      }
    } catch (error: any) {
      // This error is being reported often in Bugsnag. It is produced
      // by an unkown operation system using old Chromium versions.
      // Because it is causing too many noise on bugsnag we are
      // silencing it for now.
      if (
        error instanceof TypeError &&
        error.message.match(FAILED_TO_LOAD_HTML_ERROR_MESSAGE)
      ) {
        // eslint-disable-next-line no-console
        console.error(error);
      } else {
        Bugsnag.notify(error, (event) => {
          event.addMetadata('component', this._componentMetadata());
        });
      }
      this._clearShadowRoot();
    }
  }

  async updateLearnMoreButtonAndModal() {
    if (!this._i18n) return;

    // Open modal on 'Learn more' click
    const learnMoreBtn = this.shadowRoot?.querySelector(
      '.shopify-installments__learn-more',
    );
    const modalToken = uuidv4();
    if (learnMoreBtn) {
      learnMoreBtn.innerHTML = this._i18n.translate('banner.learn_more');

      this._monorailTracker.trackInstallmentsBannerImpression(
        this._metaType,
        InstallmentsBannerContent.PayInFour,
        this._eligible,
        BannerTemplateCodeSignature.CustomizedByMerchant,
        false,
        undefined,
        this._currentVariantId,
      );

      const learnMoreEventListener = async () => {
        // this should always be set by either handleCartMeta or
        // handleProductMeta, but if the `shopify-meta` attribute, or the
        // payload are not valid then we won't have a price, in which case we
        // don't want to continue any further.
        if (!this._pricePerTerm) {
          return;
        }

        if (!this._open) {
          this._open = true;
          this._modalMonorailTracker.trackElementImpression(this._metaType);
          const priceRange = {
            minPrice: this._minPrice,
            maxPrice: this._maxPrice,
          };

          const terms = new ShopifyInstallmentsModal(
            this._pricePerTerm,
            this._eligible,
            priceRange,
            this._loanTypes,
            modalToken,
            this._modalMonorailTracker,
            undefined,
            MinIneligibleMessageType.SplitPay,
          );

          await terms.connectedCallback();
          const focusLock = new FocusLock(terms.focusLockTarget);
          terms.addEventListener('shopify_modal_close', () => {
            this._open = false;
            closePaymentTermsModal();
            // Release lock on modal for a11y purposes
            focusLock.release(learnMoreBtn);
          });

          openPaymentTermsModal(terms);
          this._instrumentMonorailModalOpenEvent(terms, this._metaType);
          // Lock focus on modal for a11y purposes
          focusLock.lock();
        }
      };

      // Remove current event listener from learnMore button, as appending new listeners leads to some odd behaviour
      if (this._lastLearnMoreEventListener) {
        learnMoreBtn.removeEventListener(
          'click',
          this._lastLearnMoreEventListener,
        );
      }
      this._lastLearnMoreEventListener = learnMoreEventListener;
      learnMoreBtn.addEventListener('click', learnMoreEventListener);
    }
  }

  handleProductMeta(productMeta: LegacyProductShopifyMetadata) {
    this._variants = productMeta.variants;
    this._minPrice = productMeta.min_price;
    this._minPriceNumber = convertPriceToNumber(this._minPrice);
    this._maxPrice = productMeta.max_price;
    this._maxPriceNumber = convertPriceToNumber(this._maxPrice);
    this._currentVariantId = Number(this.getAttribute('variant-id'));
    this._pricePerTerm = this.updateVariant(this._currentVariantId);
    this._numberOfPaymentTerms = productMeta.number_of_payment_terms;

    // Update price on variant change, need to navigate up 2 shadow roots to get to form
    const productForm = this._getProductForm();
    if (productForm) {
      const onProductFormChange = (attempts = 0) => {
        // The value of the <select> element updates after an unknown amount of time based on how much
        // work a theme's custom JS is handling. We are putting some limitations by checking every 100ms
        // for up to 500ms.
        if (attempts > 4) {
          return;
        }

        const newVariantId = getNewProductVariantId(productForm);
        // Out of options as to find the new variant ID.
        if (!newVariantId) return;

        if (this._currentVariantId === newVariantId) {
          setTimeout(() => {
            onProductFormChange(attempts + 1);
          }, 100);
        } else {
          this._pricePerTerm = this.updateVariant(newVariantId);
          this._currentVariantId = newVariantId;
          this.updateLearnMoreButtonAndModal();
        }
      };
      // We don't add the event listener if it's been added before.
      if (!this._hasChangeEventListener) {
        this._hasChangeEventListener = true;
        productForm.addEventListener('change', () => {
          onProductFormChange();
        });
      }
    }
  }

  handleCartMeta(cartMeta: LegacyCartShopifyMetadata) {
    const extraCartSubtotalSelectors: string | null =
      cartSubtotalSelectorsForTheme();
    this._minPrice = cartMeta.min_price;
    this._minPriceNumber = convertPriceToNumber(this._minPrice);
    this._maxPrice = cartMeta.max_price;
    this._maxPriceNumber = convertPriceToNumber(this._maxPrice);
    this._loanTypes = cartMeta.available_loan_types;
    this._eligible = cartMeta.eligible;
    this._pricePerTerm = cartMeta.price;
    this._numberOfPaymentTerms = cartMeta.number_of_payment_terms;
    this.updateBannerPrice(this._pricePerTerm);

    if (!extraCartSubtotalSelectors) {
      confirmCartSubtotalAttribute();
    }

    // Update on cart change
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.nodeType !== Node.ELEMENT_NODE) {
          return;
        }
        const mutationTarget = mutation.target as Element;
        if (
          (mutationTarget.matches('[data-cart-subtotal]') ||
            (extraCartSubtotalSelectors &&
              mutationTarget.matches(extraCartSubtotalSelectors))) &&
          mutationTarget.textContent
        ) {
          const paymentFullPrice = convertPriceToNumber(
            mutationTarget.textContent,
          );
          if (paymentFullPrice) {
            this._eligible = this._priceEligible(paymentFullPrice);
            const termPrice = this._splitCartPrice(paymentFullPrice);
            if (termPrice) {
              const formattedPrice = formatPrice(termPrice);
              this._pricePerTerm = formattedPrice;
              this.updateBannerPrice(formattedPrice);
            }
          }
        }
      });
    });

    mutationObserver.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  getContent = (price?: string) => {
    if (!this._i18n) return '';

    const shopPayLogo = SHOP_PAY_LOGO(this._backgroundColor);

    if (!this._loanTypes.length) {
      return this.getIneligibleContent();
    }

    if (this._loanTypes.includes(AvailableLoanType.SplitPay)) {
      return this._i18n.translate('banner.split_pay_eligible', {
        price: price || '',
        shopPayLogo,
      });
    }

    const onlyInterest = this._loanTypes.includes(AvailableLoanType.Interest);
    return onlyInterest
      ? this._i18n.translate('banner.interest_only_eligible', {shopPayLogo})
      : this.getIneligibleContent();
  };

  getIneligibleContent = () => {
    if (!this._i18n) return '';
    const shopPayLogo = SHOP_PAY_LOGO(this._backgroundColor);

    return this._i18n.translate('banner.non_eligible_min', {
      shopPayLogo,
      minPrice: this._minPrice,
    });
  };

  updateVariant = (variantId: number) => {
    const variant = this._variants?.find(
      (variant) => Number(variant.id) === variantId,
    );
    this._eligible = Boolean(variant?.eligible);
    this._loanTypes = variant?.available_loan_types || [];

    this.updateBannerPrice(variant?.price);

    return variant?.price || '';
  };

  calculatePricePerTerm = (price: number, term: Term) => {
    // Divided by 100 for percentage and 12 to get per month rate
    const interestRatePerMonth = term.apr / 1200;
    const numberOfPayments = term.installments_count;

    if (interestRatePerMonth === 0) {
      return formatPrice(price / numberOfPayments);
    }

    const numerator =
      price *
      interestRatePerMonth *
      (1 + interestRatePerMonth) ** numberOfPayments;
    const denominator = (1 + interestRatePerMonth) ** numberOfPayments - 1;

    return formatPrice(numerator / denominator);
  };

  updateBannerPrice = (price?: string) => {
    let content;
    if (this._eligible) {
      content = this.getContent(price);
    } else {
      content = this.getIneligibleContent();
    }

    const installmentsContent = this.shadowRoot?.querySelector(
      '#shopify-installments-content',
    );
    if (installmentsContent) {
      installmentsContent.innerHTML = content;
    }
  };

  private _splitCartPrice(price: number) {
    if (isNaN(price)) {
      return undefined;
    }
    return Math.floor((price / this._numberOfPaymentTerms) * 100) / 100;
  }

  private _priceEligible(price: number) {
    return (
      this._minPriceNumber != null &&
      this._maxPriceNumber != null &&
      price >= this._minPriceNumber &&
      price <= this._maxPriceNumber
    );
  }

  private _instrumentMonorailModalOpenEvent(
    modal: ShopifyInstallmentsModal,
    elementType: InstallmentsBannerType,
  ) {
    this._modalMonorailTracker.trackModalOpened(
      elementType,
      modal.getModalToken(),
      modal.getModalType(),
      JSON.stringify([]),
      this._currentVariantId,
      undefined,
    );
  }

  private _getProductForm() {
    return (
      this.shadowRoot?.host.parentNode as ShadowRoot | null
    )?.host?.closest('form');
  }

  private _clearShadowRoot() {
    if (this.shadowRoot) {
      this.shadowRoot.innerHTML = '';
    }
  }

  private _componentMetadata(): ComponentMetadata {
    return {
      name: 'shop-pay-banner',
      shopifyMeta: this.getAttribute('shopify-meta'),
      variantId: this.getAttribute('variant-id'),
    };
  }
}

/**
 * Define the shop-pay-banner custom element.
 */
export function defineElement() {
  defineCustomElement('shop-pay-banner', ShopPayBanner);
}
